<template>
  <label class="checkbox-switch" @click.stop>
    <input
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: ['value'],
};
</script>
<style lang="scss" scoped>
@import '@/scss/base.scss';
.checkbox-switch {
  display: inline-block;
  height: 20px;
  min-width: 40px;
  position: relative;
  width: 40px;

  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + .slider {
      background-color: $lightAccent;

      &::before {
        @include transform(translateX(20px));
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $lightAccent;
    }
  }

  .slider {
    background-color: #d2d2d2;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    @include transition($transition);

    &::before {
      background-color: white;
      border-radius: 50%;
      bottom: 2px;
      content: '';
      height: 16px;
      left: 2px;
      position: absolute;
      @include transition($transition);
      width: 16px;
    }
  }

  &:hover {
    input {
      &:checked + .slider {
        background-color: rgba($color: $lightAccent, $alpha: 0.8);
      }
      &:not(:checked) + .slider {
        background-color: rgba($color: #d2d2d2, $alpha: 0.8);
      }
    }
  }
}
</style>
