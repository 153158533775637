<template>
  <div class="styleguide">
    <p>
      <Button>Откажи</Button>
    </p>
    <p>
      <Button disabled="true">Откажи</Button>
    </p>
    <p>
      <Button class="button--blue">Запази</Button>
    </p>
    <p>
      <Button disabled="true" class="button--blue">Запази</Button>
    </p>
    <p>
      <Button class="button--red">Анулирай</Button>
    </p>
    <p>
      <Button disabled="true" class="button--red">Анулирай</Button>
    </p>
    <p>
      <Button class="button--big button--grey">
        <span class="icon">B</span>
        Откажи
      </Button>
    </p>
    <p>
      <Button class="button--big button--grey" disabled="true">
        <span class="icon">B</span>
        Откажи
      </Button>
    </p>
    <p><CheckboxSwitch /></p>
    <p>
      <Input placeholder="placeholder" />
    </p>
    <p>
      <Input placeholder="placeholder" class="input-wrapper--big" />
    </p>
    <p>
      <Input
        placeholder="dropdown"
        :list="['option 1', 'option 2', 'option 3']"
      />
    </p>
  </div>
</template>

<script>
import Button from '@/components/Button';
import CheckboxSwitch from '@/components/inputs/CheckboxSwitch';
import Input from '@/components/inputs/Input';
export default {
  components: {
    Button,
    CheckboxSwitch,
    Input,
  },
};
</script>


<style lang="scss" scoped>
@import '@/scss/base.scss';
.styleguide {
  padding: 1rem;
}
p {
  margin-bottom: 1rem;
}
</style>